import React from 'react'
import MicIcon from '@mui/icons-material/Mic'
import MicOffIcon from '@mui/icons-material/MicOff'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import ScreenShareIcon from '@mui/icons-material/ScreenShare'
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare'
import { AspectRatio } from "react-aspect-ratio"
import { useParticipant, VideoRenderer } from 'livekit-react'
import styled from 'styled-components'

const ParticipantBar = styled.div`
	background: rgba(0,0,0,0.6);
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
`

export default ({
	participant,
	width,
	height,
	aspectWidth,
	aspectHeight,
	orientation,
	displayName,
	showOverlay,
	onMouseEnter,
	onMouseLeave,
	onClick,
	type,
}) => {
	const { cameraPublication, screenSharePublication, isLocal } = useParticipant(participant)

	let videoPublication = cameraPublication
	if (type === 'screenshare') {
		videoPublication = screenSharePublication
	}

	const containerStyles = {
		width: width,
		height: height,
	}

	// when aspect matches, cover instead
	let objectFit= "contain"
	let videoOrientation
	if (!orientation && aspectWidth && aspectHeight) {
		orientation = aspectWidth > aspectHeight ? "landscape" : "portrait"
	}
	if (videoPublication?.dimensions) {
		videoOrientation =
		videoPublication.dimensions.width > videoPublication.dimensions.height
			? "landscape"
			: "portrait"
	}

	if (videoOrientation === orientation) {
		objectFit = "cover"
	}

	if (!displayName) {
		displayName = participant.identity
	}

	let mainElement
	if (
		videoPublication?.isSubscribed &&
		videoPublication?.track &&
		!videoPublication?.isMuted
	) {
	mainElement = (
		<VideoRenderer
			track={videoPublication.track}
			isLocal={isLocal}
			objectFit={objectFit}
			width="100%"
			height="100%"
			className={type === 'screenshare' ? 'video-flip' : undefined}
		/>
	)
	} else {
		mainElement = <div />
	}

	const isAudioMuted = !participant.isMicrophoneEnabled
	const isVideoMuted = !participant.isCameraEnabled

	return (
		<div
			style={containerStyles}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
		>
			{aspectWidth && aspectHeight && (
			<AspectRatio ratio={aspectWidth / aspectHeight}>
				{mainElement}
			</AspectRatio>
			)}
			{(!aspectWidth || !aspectHeight) && mainElement}

			{showOverlay && (
				<>
					<ParticipantBar>
						<div>
							<p style={{ color: 'white', fontSize: 11, margin: 4 }}>{displayName}</p>
						</div>
					</ParticipantBar>
					<div style={{ position: 'absolute', top: 0, right: 0 }}>
						{isAudioMuted ? (
							<MicOffIcon style={{ color: 'darkgray', fontSize: 14, margin: 4 }}/>
						) : (
							<MicIcon style={{ color: 'white', fontSize: 14, margin: 4 }}/>
						)}
					</div>
					<div style={{ position: 'absolute', top: 0, right: 16 }}>
						{isVideoMuted ? (
							type === 'screenshare' ? (
								<StopScreenShareIcon style={{ color: 'darkgray', fontSize: 14, margin: 4 }}/>
							) : (
								<VideocamOffIcon style={{ color: 'darkgray', fontSize: 14, margin: 4 }}/>
							)
						) : (
							type === 'screenshare' ? (
								<ScreenShareIcon style={{ color: 'white', fontSize: 14, margin: 4 }}/>
							) : (
								<VideocamIcon style={{ color: 'white', fontSize: 14, margin: 4 }}/>
							)
						)}
					</div>
				</>
			)}
		</div>
	)
}