import React, { useState } from "react"
import { useAuth } from "../contexts/AuthContext"
import { Link } from "react-router-dom"
import { Card, CardContent, Button, FormGroup, Alert, InputLabel, Input } from "@mui/material"
import NeedAnAccount from "../components/NeedAnAccount"

export default function ForgotPassword() {
	const [email, setEmail] = useState("")
	const { resetPassword } = useAuth()
	const [error, setError] = useState("")
	const [message, setMessage] = useState("")
	const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
	e.preventDefault()

	try {
	  setMessage("")
	  setError("")
	  setLoading(true)
	  await resetPassword(email)
	  setMessage("Check your inbox for further instructions")
	} catch {
	  setError("Failed to reset password")
	}

	setLoading(false)
  }

  return (
	<div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
		<div style={{ width: 320 }}>
			<Card>
				<CardContent>
				<h2>Password Reset</h2>
				{error && <Alert severity="error">{error}</Alert>}
				{message && <Alert severity="success">{message}</Alert>}
					<FormGroup id="email">
						<InputLabel>Email</InputLabel>
						<Input type="email" onChange={(e) => setEmail(e.target.value)} required />
					</FormGroup>
					<Button disabled={loading} onClick={handleSubmit} variant="contained" style={{ marginTop: 16, marginBottom: 16 }}>
					Reset Password
					</Button>
				<div>
					<Link to="/login">{"< Back"}</Link>
				</div>
				</CardContent>
			</Card>
			<NeedAnAccount />
		</div>
	</div>
  )
}
