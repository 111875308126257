import React, { useState } from "react"
import { useAuth } from "../contexts/AuthContext"
import { useHistory } from "react-router-dom"
import { Alert, Button, Avatar } from "@mui/material"
import PersonIcon from '@mui/icons-material/Person'

const Sidebar = ({ servers, setSelectedServerUUID, selectedServerUUID }) => {
	const { currentUser } = useAuth()
	const [error, setError] = useState("")
	const { logout } = useAuth()
	const history = useHistory()

	const handleLogout = async () => {
		setError("")

		try {
			const frame = document.getElementById('chat-frame')
			if (frame) {
				frame.contentWindow.postMessage({
					event: 'logout',
				}, 'https://chat.juba.fi')
			}

			await logout()
			history.push("/login")
		} catch {
			setError("Failed to log out")
		}
	}

	const serverSelectionIndicator = (
		<div style={{ position: 'absolute', left: 0, height: '100%', width: 8, display: 'flex', alignItems: 'center' }}>
			<div style={{ height: 16, width: 4, background: 'rgba(255,255,255,0.6)', borderTopRightRadius: 4, borderBottomRightRadius: 4 }}></div>
		</div>
	)

	return (
		<div style={{ width: 64, height: '100vh', background: 'rgb(49, 33, 56)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
			{error && <Alert variant="danger">{error}</Alert>}
			<div
				style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
			>
				<div
					style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 16, cursor: 'pointer' }}
					onClick={() => setSelectedServerUUID(null)}
				>
					{selectedServerUUID === null ? (
						serverSelectionIndicator
					) : null}
					<Avatar src={currentUser.photoURL} alt="You">
						<PersonIcon />
					</Avatar>
				</div>

				{Object.keys(servers).map(serverUUID => (
					<div
						key={serverUUID}
						style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 16, cursor: 'pointer' }}
						onClick={() => setSelectedServerUUID(serverUUID)}
					>
						{selectedServerUUID === serverUUID ? (
							serverSelectionIndicator
						) : null}
						<Avatar alt="logo" src={servers[serverUUID].logo}/>
					</div>
				))}

			</div>
			
			<Button onClick={handleLogout} style={{ fontSize: 9, textAlign: 'center', marginBottom: 8}}>
			Log Out
			</Button>
		</div>
	)
}

export default Sidebar