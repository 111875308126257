import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import { LiveKitRoom, AudioRenderer, ControlsView } from 'livekit-react'
import { createLocalScreenTracks } from 'livekit-client'
import 'livekit-react/dist/index.css'
import "react-aspect-ratio/aspect-ratio.css"
import { Avatar, IconButton } from "@mui/material"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CallIcon from '@mui/icons-material/Call'
import { functions } from "../firebase"
import { useAuth } from "../contexts/AuthContext"
import ParticipantView from './ParticipantView'

const StageLayout = styled.div`
	height: calc(100% - 96px);
	width: calc(100% - 40px);
	position: relative;
	overflow: hidden;
	background: #23272A;
	padding-top: 48px;
	padding-bottom: 48px;
	padding-left: 20px;
	padding-right: 20px;
`

const getLivekitToken = functions.httpsCallable('livekitToken')
const rocketChatServerPresenceUpdate = functions.httpsCallable('rocketChatServerPresenceUpdate')

export default ({ serverUUID, roomId, roomName, onDisconnect, cameraEnabledDefault = false, microphoneEnabledDefault = false }) => {
	const { currentUser } = useAuth()
	const [hovering, sethovering] = useState(false)
	const [filmstripOpen, setFilmstripOpen] = useState(true)
	const [stageParticipants, setStageParticipants] = useState([])
	const [token, setToken] = useState(null)
	const url = 'wss://voip.juba.fi'

	const onConnected = async (room) => {
		window.voipDisconnect = room.disconnect

		await room.localParticipant.setCameraEnabled(cameraEnabledDefault)
		await room.localParticipant.setMicrophoneEnabled(microphoneEnabledDefault)

		const screenshare = async () => {
			const [videoTrack, audioTrack] = await createLocalScreenTracks({ audio: true })
			
			const videoPublication = await room.localParticipant.publishTrack(videoTrack)
			const audioPublication = await room.localParticipant.publishTrack(audioTrack)
		}
		window.screenshare = screenshare
	}

	const getToken = async () => {
		const result = await getLivekitToken({ roomId })
		if (result.data && result.data.token) {
			console.log('got the livekit token...')
			console.log(result.data.token)
			setToken(result.data.token)
		}
	}

	useEffect(() => {
		getToken()
		const interval = window.setInterval(() => {
			console.log('Updating presence...')
			rocketChatServerPresenceUpdate({
				roomId: roomId || 'none',
				serverUUID: serverUUID || currentUser.uid,
			})
		}, 10000)
		return () => {
			window.clearInterval(interval)
		}
	}, [])

	if (!token) {
		return (
			<div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<p style={{ color: 'rgba(255,255,255,0.33)' }}>Authenticating...</p>
			</div>
		)
	}

	const toggleOnStage = (sid) => {
		if (stageParticipants.includes(sid)) {
			setStageParticipants(stageParticipants.filter(p => p !== sid))
		} else {
			setStageParticipants(stageParticipants.concat(sid))
		}
	}

	// renderStage prepares the layout of the stage using subcomponents. Feel free to
	// modify as you see fit. It uses the built-in ParticipantView component in this
	// example; you may use a custom component instead.
	function renderStage({ roomState }) {
		const { room, participants, audioTracks, isConnecting, error } = roomState

		if (isConnecting) {
			return (
				<div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<p style={{ color: 'rgba(255,255,255,0.33)' }}>Connecting...</p>
				</div>
			)
		}
		if (error) {
			return (
				<div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<p style={{ color: 'rgba(255,255,255,0.33)' }}>Error: {error.message}</p>
				</div>
			)
		}
		if (!room) {
			return (
				<div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<p style={{ color: 'rgba(255,255,255,0.33)' }}>Room closed</p>
				</div>
			)
		}

		const audioParticipants = []
		const cameraParticipants = []
		const screenshareParticipants = []
		participants.forEach(p => {
			if (p.videoTracks && p.videoTracks.size > 0) {
				p.videoTracks.forEach(v => {
					if (v.source === 'screen_share') {
						screenshareParticipants.push(p)
					} else {
						cameraParticipants.push(p)
					}
				})
			} else {
				audioParticipants.push(p)
			}
		})

		const existingStageParticipants = stageParticipants.filter(p => (
			audioParticipants.find(participant => participant.sid + 'audio' === p) ||
			cameraParticipants.find(participant => participant.sid + 'camera' === p) ||
			screenshareParticipants.find(participant => participant.sid + 'screenshare' === p)
		))

		const renderParticipant = (participant, type) => (
			existingStageParticipants.includes(participant.sid + type) ? (
				<div
					key={participant.sid + type}
					style={{
						borderColor: 'rgba(255,255,255,0.33)',
						borderStyle: 'solid',
						borderWidth: 2,
						borderRadius: 6,
						marginBottom: 2,
						position: 'relative',
						overflow: 'hidden',
						width: 'calc(100% / ' + existingStageParticipants.length + ' - 8px)',
						height: 'calc(100% - 8px)',
						display: 'flex',
						background: 'rgb(30,30,30)',
					}}
				>
					<ParticipantView
						participant={participant}
						height={'auto'}
						width={'100%'}
						type={type}
					/>
					{hovering ? (
						<p style={{
							position: 'absolute',
							right: 8,
							top: 8,
							margin: 0,
							background: 'rgba(0,0,0,0.8)',
							borderRadius: 2,
							paddingTop: 4,
							paddingBottom: 4,
							paddingLeft: 6,
							paddingRight: 6,
							color: 'white',
							fontSize: 12
						}}>
							{participant.identity}
						</p>
					 ) : null}
				</div>
			) : null
		)

		const renderParticipantPreview = (participant, type) => (
			<div
				key={participant.identity}
				style={{
					borderColor: participant.isSpeaking ? 'white' : 'black',
					borderStyle: 'solid',
					borderWidth: 2,
					borderRadius: 6,
					marginBottom: 2,
					position: 'relative',
					minHeight: 72,
					background: 'gray',
				}}
			>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					position: 'absolute',
					width: '100%',
					height: '100%',
				}}>
					<Avatar>{participant.identity ? participant.identity[0] : '?'}</Avatar>
				</div>
				
				<ParticipantView
					key={participant.sid}
					participant={participant}
					showOverlay={true}
					height={'auto'}
					width={124}
					onClick={() => toggleOnStage(participant.sid + type)}
					type={type}
				/>
				{existingStageParticipants.includes(participant.sid + type) ? (
					<CheckCircleIcon
						style={{
							height: 16,
							width: 16,
							position: 'absolute',
							left: 2,
							top: 2,
							color: 'green',
							background: 'white',
							borderRadius: 8,
						}}
					/>
				) : null}
			</div>
		)

		return (
			<StageLayout
				onMouseEnter={() => sethovering(true)}
				onMouseLeave={() => sethovering(false)}
			>
				<div style={{
					height: '100%',
					width: filmstripOpen ? 'calc(100% - 128px)' : '100%',
					overflow: 'hidden',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-around',
					alignItems: 'center',
				}}>
					{existingStageParticipants.length ? (
						screenshareParticipants.map(participant => (
							renderParticipant(participant, 'screenshare')
						)).concat(
							cameraParticipants.map(participant => (
								renderParticipant(participant, 'camera')
							))
						).concat(
							audioParticipants.map(participant => (
								renderParticipant(participant, 'audio')
							))
						)
					) : (
						<p style={{ color: 'rgba(255,255,255,0.33)' }}>You don't have anyone on the stage. You can select people from the right.</p>
					)}
					{audioTracks.map(track => (
						<AudioRenderer key={track.sid} track={track} isLocal={false} />
					))}
				</div>

				{filmstripOpen ? (
					<div style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						position: 'absolute',
						top: 0,
						right: 0,
						width: 128,
						height: '100%',
						overflowY: 'auto',
					}}>
						{screenshareParticipants.map(participant => (
							renderParticipantPreview(participant, 'screenshare')
						))}
						{cameraParticipants.map(participant => (
							renderParticipantPreview(participant, 'camera')
						))}
						{audioParticipants.map(participant => (
							renderParticipantPreview(participant, 'audio')
						))}
					</div>
				) : false}

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: 'center',
						position: 'absolute',
						bottom: 4,
						width: '100%',
						opacity: hovering ? 1 : 0,
						transform: 'scale(0.8)',
					}}
				>
					<ControlsView
						room={room}
						enableScreenShare={true}
						onLeave={() => {
							rocketChatServerPresenceUpdate({
								roomId: 'none',
								serverUUID: serverUUID || currentUser.uid,
							})
							room.disconnect()
							onDisconnect()
						}}
					/>
				</div>

				<div style={{ position: 'absolute', right: 0, bottom: 0 }}>
					<IconButton
						aria-label="toggle-filmstrip"
						onClick={() => {
							setFilmstripOpen(!filmstripOpen)
						}}
						style={{ color: 'rgba(255,255,255,0.6)' }}
					>
						{filmstripOpen ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</div>

				<div
					style={{
						position: 'absolute',
						top: 12,
						left: 20,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						background: 'rgba(0,0,0,0.3)',
						paddingLeft: 8,
						paddingRight: 8,
						paddingTop: 6,
						paddingBottom: 6,
						borderRadius: 4,
						opacity: hovering ? 1 : 0.5,
					}}
					>
					<CallIcon style={{ fontSize: 16, marginRight: 8, color: 'white' }}/>
					<p style={{ color: 'white', margin: 0 }}>{roomName}</p>
				</div>
			</StageLayout>
		)
	}
	
	return (
		<div style={{ height: '100%', width: '100%' }}>
			<LiveKitRoom
				url={url}
				token={token}
				onConnected={room => onConnected(room)}
				stageRenderer={renderStage}
			/>
		</div>
	)
}