import { ListItem, ListItemButton, ListItemIcon, ListItemText, Avatar, Collapse, List } from "@mui/material"
import React from "react"
import truncate from 'truncate'
import styled from 'styled-components'
import PersonIcon from '@mui/icons-material/Person'
import naturalsort from 'javascript-natural-sort'

const StyledListItemText = styled(ListItemText)`
	color: rgba(255,255,255,0.7);
	margin-left: -24px;
	span {
		font-size: 12px;
	}
`
const PresenceListItemText = styled(ListItemText)`
	color: rgba(255,255,255,0.7);
	margin-left: -24px;
	span {
		font-size: 12px;
	}
`
const StyledPersonIcon = styled(PersonIcon)`
	color: rgba(255,255,255,0.7);
	font-size: 14px;
`

export default ({ dm, onSelect, serverPresence = {} }) => {
	const presenceArray = Object.keys(serverPresence).map(uid => {
		const roomId = dm._id
		if (serverPresence[uid].roomId === roomId) {
			if (serverPresence[uid].ts < (Date.now() - 25000)) {
				console.log('presence has expired')
				return null
			}
			return serverPresence[uid]
		}
		return null
	}).filter(t => t)

	const presenceElements = presenceArray.sort(byName).map(p => (
		<Collapse in={true} timeout="auto" unmountOnExit key={p.uid}>
			<List component="div" disablePadding>
				<ListItemButton sx={{ pl: 4 }}>
					<ListItemIcon>
						<Avatar
							src={p.picture}
							sx={{ height: 24, width: 24 }}
							alt={"avatar"}
						>
							{p.name[0]}
						</Avatar>
					</ListItemIcon>
					<PresenceListItemText primary={p.name} />
				</ListItemButton>
			</List>
		</Collapse>
	))

	let text = ''
	if (dm.usernames) {
		text = dm.usernames.join(', ')
	} else if (dm.topic) {
		text = dm.topic
	}

	return (
		<>
			<ListItem disablePadding key={dm._id}>
				<ListItemButton onClick={() => onSelect(dm)}>
					<ListItemIcon>
						<Avatar sx={{ width: 24, height: 24 }}>
							<StyledPersonIcon />
						</Avatar>
					</ListItemIcon>
					<StyledListItemText primary={truncate(text, 28)} />
				</ListItemButton>
			</ListItem>
			{presenceElements.length > 0 ? (
				presenceElements
			) : null}
		</>
	)
}

const byName = (a, b) => {
	const nameA = (a.name || '').toLowerCase()
	const nameB = (b.name || '').toLowerCase()

	return naturalsort(nameA, nameB)
}
