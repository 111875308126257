import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import React from "react"
import truncate from 'truncate'
import styled from 'styled-components'
import { useAuth } from "../contexts/AuthContext"
import { useHistory } from "react-router"
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const StyledListItem = styled(ListItem)`
	background: rgba(255,255,255,0.1);
`
const StyledListItemText = styled(ListItemText)`
	color: rgba(255,255,255,0.87);
	margin-left: -8px;
	span {
		font-weight: bold;
	}
`
const StyledListItemIcon = styled(ListItemIcon)`
	color: rgba(255,255,255,0.6);
	position: absolute;
	right: 8px;
	min-width: 16px;
`

export default () => {
	const { currentUser } = useAuth()
	const history = useHistory()
	return (
		<StyledListItem disablePadding key={currentUser.id}>
			<ListItemButton onClick={() => history.push('/update-profile')}>
				<StyledListItemText primary={truncate(currentUser.displayName, 32)} />
				<StyledListItemIcon>
					<ChevronRightIcon />
				</StyledListItemIcon>
			</ListItemButton>
		</StyledListItem>
	)
}
