import React, { useEffect, useState } from "react"
import { List, Skeleton } from "@mui/material"
import debounce from 'debounce'
import { functions, firestore } from "../firebase"
import styled from 'styled-components'
import DMListItem from "./DMListItem"
import UserListItem from "./UserListItem"
import { useAuth } from "../contexts/AuthContext"

const Header = styled.h4`
	color: rgba(255,255,255,0.5);
	padding-left: 16px;
	margin-bottom: 0;
`
const TextSkeleton = <Skeleton variant="text" sx={{ bgcolor: 'grey.600', marginLeft: 1, width: 'calc(100% - 32px)' }} />
const AvatarSkeleton = <Skeleton variant="circular" width={24} height={24} sx={{ bgcolor: 'grey.600' }}/>
const DMSkeleton = (
	<div style={{ display: 'flex', flexDirection: 'row', marginRight: 16, marginLeft: 16, marginTop: 16 }}>
		{AvatarSkeleton}
		{TextSkeleton}
	</div>
)

const rocketChatDMs = functions.httpsCallable('rocketChatDMs')

const DMBrowser = ({ setTextRoom }) => {
	const { currentUser } = useAuth()
	const [loading, setLoading] = useState(true)
	const [DMs, setDMs] = useState([])
	const [serverPresence, setServerPresence] = useState(null)

	useEffect(() => {
		const unsub = firestore.collection("server-presence").doc(currentUser.uid)
			.onSnapshot((doc) => {
				setServerPresence(doc.data())
			})
		return unsub
	}, [currentUser.uid])

	const getDMs = async () => {
		const result = await rocketChatDMs()
		if (result.data) {
			console.log('got the DMs...')
			console.log(result)
			if (result.data.dms) {
				setDMs(result.data.dms)
				setLoading(false)
			} else {
				setDMs([])
			}
			return true
		}
		console.log('Failed to load DMs!')
		return false
	}

	useEffect(() => {
		getDMs()
		window.refreshDMs = debounce(getDMs, 1000)
		return () => {
			window.refreshDMs = null
		}
	}, [])

	const renderDMs = () => {
		const elements = DMs.map(dm => {
			return (
				<DMListItem key={dm._id} dm={dm} onSelect={setTextRoom} serverPresence={serverPresence}/>
			)
		})
		return (
			<>
				<Header>Direct Messages</Header>
				<List>
					{elements}
				</List>
			</>
		)
	}

	const renderLoading = () => {
		return (
			<>
				<Header>Direct Messages</Header>
				<List>
					{DMSkeleton}
					{DMSkeleton}
					{DMSkeleton}
					{DMSkeleton}
					{DMSkeleton}
					{DMSkeleton}
					{DMSkeleton}
					{DMSkeleton}
				</List>
			</>
		)
	}

	return (
		<div
			style={{
				width: 256,
				minWidth: 256,
				maxWidth: 256,
				height: '100vh',
				background: '#23272A',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
			}}
		>
			<UserListItem />
			{loading ? renderLoading() : renderDMs()}
		</div>
	)
}

export default DMBrowser