import React, { useState } from "react"
import { useAuth } from "../contexts/AuthContext"
import styled from 'styled-components'

const StyledImg = styled.img`
	width: 100%;
	height: auto;
	height-max: 500px;
`

export default ({picture, setPicture}) => {
	const [preview, setPreview] = useState(null)
	const { currentUser } = useAuth()

	const onImageChange = (event) => {
		let file = null
		if (event.target.files && event.target.files[0]) {
			file = event.target.files[0]
		}
		setPreview(URL.createObjectURL(file))
		setPicture(file)
	}

	return (
		<div>
			<StyledImg src={preview ? preview : currentUser.photoURL} />
			<input type="file" name="profile picture" onChange={onImageChange}/>
		</div>
	)
}