import React, { useState } from "react"
import { FormGroup, InputLabel, Button, Card, Alert, CardContent, Input } from "@mui/material"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import NeedAnAccount from "../components/NeedAnAccount"

export default function Login() {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const { login } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	async function handleSubmit(e) {
		e.preventDefault()

		try {
			setError("")
			setLoading(true)
			await login(email, password)
			history.push("/")
		} catch {
			setError("Failed to log in")
		}

		setLoading(false)
	}

	return (
		<div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<div style={{ width: 320 }}>
				<Card>
					<CardContent>
						<h2>Log In</h2>
						{error && <Alert severity="error">{error}</Alert>}
						<FormGroup id="email">
							<InputLabel>Email</InputLabel>
							<Input type="email" onChange={(e) => setEmail(e.target.value)} required />
						</FormGroup>
						<FormGroup id="password">
							<InputLabel>Password</InputLabel>
							<Input type="password" onChange={(e) => setPassword(e.target.value)} required />
						</FormGroup>
						<Button disabled={loading} onClick={handleSubmit} variant="contained" style={{ marginTop: 16, marginBottom: 16 }}>
							Log In
						</Button>
						<div>
							<Link to="/forgot-password">Forgot Password?</Link>
						</div>
					</CardContent>
				</Card>
				<NeedAnAccount />
			</div>
		</div>
	)
}
