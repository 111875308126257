import React from "react"
import { AuthProvider } from "./contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Dashboard from "./routes/Dashboard"
import Signup from "./routes/Signup"
import Login from "./routes/Login"
import PrivateRoute from "./routes/PrivateRoute"
import ForgotPassword from "./routes/ForgotPassword"
import UpdateProfile from "./routes/UpdateProfile"

function App() {
  return (
    <div
      style={{ minHeight: "100vh", margin: 0, padding: 0 }}
    >
      <div style={{ width: '100vw', height: '100vh' }}>
        <Router>
          <AuthProvider>
            <Switch>
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute path="/update-profile" component={UpdateProfile} />
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
            </Switch>
          </AuthProvider>
        </Router>
      </div>
    </div>
  )
}

export default App
