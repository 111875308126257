import React, { useEffect, useState } from "react"
import { functions } from "../firebase"
import Sidebar from "../components/Sidebar"
import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'

import 'react-reflex/styles.css'
import VOIPView from "../components/VOIPView"
import ChannelBrowser from "../components/ChannelBrowser"
import DMBrowser from "../components/DMBrowser"
import serverConfig from '../serverConfig.js'
import { useAuth } from "../contexts/AuthContext"
import CallIcon from '@mui/icons-material/Call'
import TagIcon from '@mui/icons-material/Tag'
import LockIcon from '@mui/icons-material/Lock'
import VideocamIcon from '@mui/icons-material/Videocam'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { Button } from "@mui/material"
import styled from "styled-components"

const CallButton = styled(Button)`
	span {
		margin: 0;
	}
`
const StyledTagIcon = styled(TagIcon)`
	font-size: 16px;
	margin-right: 8px;
	color: white;
`
const StyledLockIcon = styled(LockIcon)`
	font-size: 16px;
	margin-right: 8px;
	color: white;
`
const StyledAtIcon = styled(AlternateEmailIcon)`
	font-size: 16px;
	margin-right: 8px;
	color: white;
`


const sso = functions.httpsCallable('rocketChatSSO')
const rocketChatServerPresenceUpdate = functions.httpsCallable('rocketChatServerPresenceUpdate')

export default () => {
	const { currentUser } = useAuth()
	const [loading, setLoading] = useState(true)
	const [selectedServerUUID, setSelectedServerUUID] = useState(null)
	const [voipRoomId, setVoipRoomId] = useState(null)
	const [voipRoomName, setVoipRoomName] = useState(null)
	const [cameraEnabledDefault, setCameraEnabledDefault] = useState(false)
	const [microphoneEnabledDefault, setMicrophoneEnabledDefault] = useState(false)
	const [textRoom, setTextRoom] = useState(null)
	const { setLocalUserProfile } = useAuth()

	const _setVoipRoom = ({ roomId, roomName }) => {
		if (window.voipDisconnect) {
			window.voipDisconnect()
			setVoipRoomId(null)
			setVoipRoomName(null)
		}
		window.setTimeout(() => {
			setVoipRoomId(roomId)
			setVoipRoomName(roomName)
			rocketChatServerPresenceUpdate({
				roomId: roomId || 'none',
				serverUUID: selectedServerUUID || currentUser.uid,
			})
		}, 100)
	}

	const selectChannel = (room) => {
		const frame = document.getElementById('chat-frame')
		const path = `/channel/${room.topic || room.name}?layout=embedded`
		console.log('changing room id to', path)
		const textRoom = {
			...room,
			voipRoomId: room.topic || room.name,
			textRoomName: room.topic || room.name,
		}
		setTextRoom(textRoom)
		frame.contentWindow.postMessage({
			externalCommand: 'go',
			path,
		}, 'https://chat.juba.fi')
	}

	const selectGroup = (room) => {
		const frame = document.getElementById('chat-frame')
		const path = `/group/${room.name}?layout=embedded`
		const textRoom = {
			...room,
			voipRoomId: room.name,
			textRoomName: room.name,
		}
		setTextRoom(textRoom)
		console.log('changing room id to', path)
		frame.contentWindow.postMessage({
			externalCommand: 'go',
			path,
		}, 'https://chat.juba.fi')
	}

	const selectDM = (room) => {
		const frame = document.getElementById('chat-frame')
		const path = `/direct/${room._id}`
		const textRoom = {
			...room,
			voipRoomId: room._id,
			textRoomName: room.usernames.join(', '),
		}
		setTextRoom(textRoom)
		console.log('changing room id to', path)
		frame.contentWindow.postMessage({
			externalCommand: 'go',
			path,
		}, 'https://chat.juba.fi')
	}

	const _setTextRoom = (room) => {
		window.setTimeout(() => {
			if (room.t === 'c') {
				selectChannel(room)
			} else if (room.t === 'd') {
				selectDM(room)
			} else {
				selectGroup(room)
			}
		}, 100)
	}

	const doLogin = async () => {
		const result = await sso()
		if (result.data && result.data.loginToken) {
			console.log('got the loginToken. Waiting a bit...')
			window.setTimeout(() => {
				const frame = document.getElementById('chat-frame')
				console.log('attempting login with token...')
				frame.contentWindow.postMessage({
					event: 'logout',
				}, 'https://chat.juba.fi')
				window.setTimeout(() => {
					frame.contentWindow.postMessage({
						event: 'login-with-token',
						loginToken: result.data.loginToken,
					}, 'https://chat.juba.fi')
					setLocalUserProfile(result.data.userProfile)
					setLoading(false)
				}, 500)
			}, 500)
		}
	}

	const listenEvents = () => {
		window.addEventListener('message', (e) => {
			if (e.origin === 'https://chat.juba.fi') {
				handleChatEvent(e)
			}
		})
	}

	const handleChatEvent = (e) => {
		console.log(e.data.eventName)
		/*if (e.data.eventName === 'room-opened') {
			const room = e.data.data
			setTextRoom(room)
		}*/
		if (e.data.eventName === 'unread-changed-by-subscription' || e.data.eventName === 'notification') {
			if (window.refreshChannels) {
				window.refreshChannels()
			}
			if (window.refreshDMs) {
				window.refreshDMs()
			}
		}
	}

	useEffect(() => {
		doLogin()
		listenEvents()
	}, [])

	let serverBrowser = <DMBrowser setTextRoom={_setTextRoom}/>
	if (selectedServerUUID && serverConfig.servers[selectedServerUUID]) {
		serverBrowser = <ChannelBrowser serverUUID={selectedServerUUID} setTextRoom={_setTextRoom}/>
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'row', width: '100vw', height: '100vh' }}>

			{loading ? (
				<div
					style={{
						position: 'absolute',
						width: '100vw',
						height: '100vh',
						background: '#23272A',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<img src={'/loading.png'} alt={'loading...'}/>
				</div>
			) : (
				<>
					<Sidebar servers={serverConfig.servers} selectedServerUUID={selectedServerUUID} setSelectedServerUUID={setSelectedServerUUID}/>
					{serverBrowser}
				</>
			)}

			{
				<ReflexContainer orientation="horizontal">
					
					<ReflexElement className="right-pane" minSize={voipRoomId ? 64 : 0} flex={voipRoomId ? 0.5 : 0}>
						{voipRoomId ? (
							<VOIPView
								serverUUID={selectedServerUUID}
								roomId={voipRoomId ? voipRoomId : null}
								roomName={voipRoomName ? voipRoomName : null}
								onDisconnect={() => _setVoipRoom({ roomId: null, roomName: null })}
								cameraEnabledDefault={cameraEnabledDefault}
								microphoneEnabledDefault={microphoneEnabledDefault}
							/>
						) : null}
					</ReflexElement>
					
				
					<ReflexSplitter style={loading || !voipRoomId ? { height: 0, pointerEvents: 'none' } : undefined}/>

					<ReflexElement className="left-pane">
						<div style={{ width: '100%', height: '100%' }}>
							<iframe
								title="chat"
								id="chat-frame"
								src="https://chat.juba.fi/home?layout=embedded"
								style={{
									width: loading ? 0 : '100%',
									height: textRoom && textRoom.voipRoomId !== voipRoomId ? 'calc(100% - 48px)' : '100%'
								}}
								frameBorder="0"
							/>
							{textRoom && textRoom.voipRoomId !== voipRoomId ? (
								<div
									style={{
										position: 'absolute',
										bottom: 0,
										left: 0,
										right: 0,
										width: '100%',
										height: 48,
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'flex-end',
									}}
								>
									<div
										style={{
											position: 'absolute',
											top: 12,
											left: 20,
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											background: 'rgba(0,0,0,0.3)',
											paddingLeft: 8,
											paddingRight: 8,
											paddingTop: 6,
											paddingBottom: 6,
											borderRadius: 4,
										}}
									>
										{textRoom.t === 'c' ? (
											<StyledTagIcon />
										) : (
											textRoom.t === 'd' ? (
												<StyledAtIcon />
											) : (
												<StyledLockIcon />
											)
										)}
										<p style={{ color: 'white', margin: 0 }}>{textRoom.textRoomName}</p>
									</div>
									<CallButton
										onClick={() => {
											setCameraEnabledDefault(false)
											setMicrophoneEnabledDefault(true)
											_setVoipRoom({ roomId: textRoom.voipRoomId, roomName: textRoom.textRoomName })
										}}
										variant="contained"
										startIcon={<CallIcon />}
										style={{
											background: '#3BA55D',
											marginLeft: 8,
											marginRight: 8,
										}}
									/>
									<CallButton
										onClick={() => {
											setCameraEnabledDefault(true)
											setMicrophoneEnabledDefault(true)
											_setVoipRoom({ roomId: textRoom.voipRoomId, roomName: textRoom.textRoomName })
										}}
										variant="contained"
										startIcon={<VideocamIcon />}
										style={{
											background: '#3BA55D',
											marginLeft: 8,
											marginRight: 8,
										}}
									/>
								</div>
							) : null}
						</div>
					</ReflexElement>
				</ReflexContainer>
			}
		</div>
	)
}
