import firebase from "firebase/app"
import "firebase/auth"
import "firebase/functions"
import "firebase/storage"
import "firebase/firestore"

const app = firebase.initializeApp({
  apiKey: "AIzaSyAYU_ICITiAxdR-du8bLJOZdW6DvDhEPxI",
  authDomain: "juba-stg.firebaseapp.com",
  projectId: "juba-stg",
  storageBucket: "juba-stg.appspot.com",
  messagingSenderId: "860131187835",
  appId: "1:860131187835:web:ea1940c30e80dd81a556de"
})

export const auth = app.auth()
export const functions = app.functions("europe-west1")
export const storage = app.storage()
export const firestore = app.firestore()
export default app

window.firebase = app