import { ListItem, ListItemButton, ListItemIcon, ListItemText, Badge, Collapse, List, Avatar } from "@mui/material"
import React from "react"
import TagIcon from '@mui/icons-material/Tag'
import LockIcon from '@mui/icons-material/Lock'
import truncate from 'truncate'
import styled from 'styled-components'
import naturalsort from 'javascript-natural-sort'

const StyledListItemText = styled(ListItemText)`
	color: rgba(255,255,255,0.7);
	margin-left: -32px;
	span {
		font-size: 12px;
	}
`
const PresenceListItemText = styled(ListItemText)`
	color: rgba(255,255,255,0.7);
	margin-left: -24px;
	span {
		font-size: 12px;
	}
`
const JoinedTagIcon = styled(TagIcon)`
	color: rgba(255,255,255,0.7);
	font-size: 16px;
`
const JoinedLockIcon = styled(LockIcon)`
	color: rgba(255,255,255,0.7);
	font-size: 16px;
`
const StyledBadge = styled(Badge)`
	transform: scale(0.75);
	margin-right: 8px;
`

export default ({ channel, onSelect, serverPresence = {} }) => {
	const presenceArray = Object.keys(serverPresence).map(uid => {
		const roomId = channel.topic || channel.name || channel._id
		if (serverPresence[uid].roomId === roomId) {
			if (serverPresence[uid].ts < (Date.now() - 25000)) {
				console.log('presence has expired')
				return null
			}
			return serverPresence[uid]
		}
		return null
	}).filter(t => t)

	const presenceElements = presenceArray.sort(byName).map(p => (
		<Collapse key={p.uid} in={true} timeout="auto" unmountOnExit>
			<List component="div" disablePadding>
				<ListItemButton sx={{ pl: 4 }}>
					<ListItemIcon>
						<Avatar
							src={p.picture}
							sx={{ height: 24, width: 24 }}
							alt={"avatar"}
						>
							{p.name[0]}
						</Avatar>
					</ListItemIcon>
					<PresenceListItemText primary={p.name} />
				</ListItemButton>
			</List>
		</Collapse>
	))

	return (
		<>
			<ListItem disablePadding key={channel._id}>
				<ListItemButton onClick={() => onSelect(channel)}>
					<ListItemIcon>
						{channel.t === 'c' ? (
							<JoinedTagIcon/>
						): (
							<JoinedLockIcon/>
						)}
					</ListItemIcon>
					<StyledListItemText primary={truncate(channel.name, 32)} />
					<StyledBadge badgeContent={channel.unread} color="secondary" />
				</ListItemButton>
			</ListItem>
			{presenceElements.length > 0 ? (
				presenceElements
			) : null}
		</>
	)
}

const byName = (a, b) => {
	const nameA = (a.name || '').toLowerCase()
	const nameB = (b.name || '').toLowerCase()

	return naturalsort(nameA, nameB)
}