import React, { useEffect, useState } from "react"
import { List, Skeleton } from "@mui/material"
import debounce from 'debounce'
import { functions, firestore } from "../firebase"
import styled from 'styled-components'
import ChannelListItem from "./ChannelListItem"

const Header = styled.h4`
	color: rgba(255,255,255,0.5);
	padding-left: 16px;
	margin-bottom: 0;
`
const TextSkeleton = <Skeleton variant="text" sx={{ bgcolor: 'grey.600', marginLeft: 1, width: 'calc(100% - 32px)' }} />
const AvatarSkeleton = <Skeleton variant="circular" width={16} height={16} sx={{ bgcolor: 'grey.600' }}/>
const ChannelSkeleton = (
	<div style={{ display: 'flex', flexDirection: 'row', marginRight: 16, marginLeft: 16, marginTop: 16 }}>
		{AvatarSkeleton}
		{TextSkeleton}
	</div>
)

const rocketChatChannels = functions.httpsCallable('rocketChatChannels')

const ChannelBrowser = ({ serverUUID, setTextRoom }) => {
	const [favorites, setFavorites] = useState([])
	const [joined, setJoined] = useState([])
	const [other, setOther] = useState([])
	const [privateChannels, setPrivateChannels] = useState([])
	const [loading, setLoading] = useState(true)
	const [serverPresence, setServerPresence] = useState(null)

	useEffect(() => {
		const unsub = firestore.collection("server-presence").doc(serverUUID)
			.onSnapshot((doc) => {
				setServerPresence(doc.data())
			})
		return unsub
	}, [serverUUID])

	const getChannels = async () => {
		const result = await rocketChatChannels()
		if (result.data) {
			console.log('got the channels...')
			console.log(result)

			const withUnread = (channel) => {
				const subscriptions = result.data.channels?.subscriptions || []
				const subFound = subscriptions.find(s => s.rid === channel._id)
				if (subFound) {
					return {...channel, unread: subFound.unread}
				}
				return channel
			}

			// Set joined channels
			if (result.data.channels?.joined) {
				let joined = [...result.data.channels.joined]
				if (result.data.channels?.favorites) {
					// Filter out favorites
					joined = joined.filter(c => !result.data.channels.favorites.find(f => f.rid === c._id))
				}
				const joinedWithourDiscussions = joined.filter(c => !c.prid)
				const joinedWithUnread = joinedWithourDiscussions.map(withUnread)
				setJoined(joinedWithUnread)
			} else {
				setJoined([])
			}

			// Set other channels
			if (result.data.channels?.all) {
				let other = [...result.data.channels.all]
				if (result.data.channels?.joined) {
					// Filter out joined channels
					other = other.filter(c => !result.data.channels.joined.find(j => j._id === c._id))
				}
				if (result.data.channels?.favorites) {
					// Filter out favorites
					other = other.filter(c => !result.data.channels.favorites.find(f => f.rid === c._id))
				}
				const otherWithourDiscussions = other.filter(c => !c.prid)
				setOther(otherWithourDiscussions)
			} else {
				setOther([])
			}

			// Set privateChannel channels
			if (result.data.channels?.private) {
				let privateChannels = [...result.data.channels.private]
				if (result.data.channels?.favorites) {
					// Filter out favorites
					privateChannels = privateChannels.filter(c => !result.data.channels.favorites.find(f => f.rid === c._id))
				}
				const privateWithUnread = privateChannels.map(withUnread)
				setPrivateChannels(privateWithUnread)
			} else {
				setPrivateChannels([])
			}

			// Set favorite channels
			if (result.data.channels?.favorites) {
				const favoritesWithUnread = result.data.channels.favorites.map(withUnread)
				setFavorites(favoritesWithUnread)
			} else {
				setFavorites([])
			}
			setLoading(false)
			return true
		}
		console.log('Failed to get channels!')
		return false
	}

	useEffect(() => {
		getChannels()
		window.refreshChannels = debounce(getChannels, 1000)
		return () => {
			window.refreshChannels = null
		}
	}, [])

	const renderFavoriteChannels = () => {
		const elements = favorites.map(c => (
			<ChannelListItem
				key={c._id || c.topic || c.name}
				channel={c}
				onSelect={setTextRoom}
				serverPresence={serverPresence}
			/>
		))
		return (
			<>
				<Header>Favorites</Header>
				<List>
					{elements}
				</List>
			</>
		)
	}

	const renderJoinedChannels = () => {
		const channelElements = joined.map(c => (
			<ChannelListItem
				key={c._id || c.topic || c.name}
				channel={c}
				onSelect={setTextRoom}
				serverPresence={serverPresence}
			/>
		))
		const privateChannelElements = privateChannels.map(c => (
			<ChannelListItem
				key={c._id || c.topic || c.name}
				channel={c}
				onSelect={setTextRoom}
				serverPresence={serverPresence}
			/>
		))
		return (
			<>
				<Header>Joined</Header>
				<List>
					{channelElements}
					{privateChannelElements}
				</List>
			</>
		)
	}

	const renderOtherChannels = () => {
		const elements = other.map(c => (
			<ChannelListItem
				key={c._id || c.topic || c.name}
				channel={c}
				onSelect={setTextRoom}
				serverPresence={serverPresence}
			/>
		))
		return (
			<>
				<Header>Other</Header>
				<List>
					{elements}
				</List>
			</>
		)
	}

	const renderLoading = () => {
		return (
			<>
				<Header>Favorites</Header>
				<List>
					{ChannelSkeleton}
					{ChannelSkeleton}
					{ChannelSkeleton}
				</List>
				<Header>Joined</Header>
				<List>
					{ChannelSkeleton}
					{ChannelSkeleton}
					{ChannelSkeleton}
					{ChannelSkeleton}
					{ChannelSkeleton}
					{ChannelSkeleton}
				</List>
				<Header>Other</Header>
				<List>
					{ChannelSkeleton}
					{ChannelSkeleton}
					{ChannelSkeleton}
					{ChannelSkeleton}
					{ChannelSkeleton}
					{ChannelSkeleton}
				</List>
			</>
		)
	}

	return (
		<div
			style={{
				width: 256,
				minWidth: 256,
				maxWidth: 256,
				height: '100vh',
				background: '#23272A',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				borderRightColor: 'rgba(255,255,255,0.2)',
				borderRightWidth: 2,
				borderRightStyle: 'solid',
			}}
		>
			{loading ? renderLoading() : null}
			{!loading ? renderFavoriteChannels() : null}
			{!loading ? renderJoinedChannels() : null}
			{!loading ? renderOtherChannels() : null}
		</div>
	)
}

export default ChannelBrowser