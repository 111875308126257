import React, { useState } from "react"
import { FormGroup, Card, CardContent, Alert, InputLabel, Input } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton'
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import { functions } from "../firebase"
import ProfilePictureSettings from "../components/ProfilePictureSettings"
import { storage } from "../firebase"

const userUsernameUpdate = functions.httpsCallable('userUsernameUpdate')
const userPhotoURLUpdate = functions.httpsCallable('userPhotoURLUpdate')

export default function UpdateProfile() {
	const { currentUser, updatePassword, updateEmail } = useAuth()
	const [email, setEmail] = useState(currentUser.email)
	const [displayName, setDisplayName] = useState(currentUser.displayName)
	const [profilePicture, setProfilePicture] = useState(null)
	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	const updateProfilePicture = async () => {
		try {
			const extension = profilePicture.name.split('.').pop()
			const ref = storage.ref().child(currentUser.uid + '/profile-picture/' + currentUser.uid + '.' + extension)
			console.log('Uploading profile picture...')
			await ref.put(profilePicture)
			const photoURL = await ref.getDownloadURL()
			console.log('Uploaded profile picture:', photoURL)
			await userPhotoURLUpdate({photoURL})
			return Promise.resolve()
		} catch (ex) {
			console.log(ex)
			throw ex
		}
	}

	function handleSubmit(e) {
		e.preventDefault()
		if (password !== passwordConfirmation) {
		return setError("Passwords do not match")
		}

		const promises = []
		setLoading(true)
		setError("")

		if (email !== currentUser.email) {
			promises.push(updateEmail(email))
		}
		
		if (displayName !== currentUser.displayName) {
			promises.push(userUsernameUpdate({username: displayName}))
		}

		if (profilePicture) {
			promises.push(updateProfilePicture())
		}

		if (password) {
			promises.push(updatePassword(password))
		}

		Promise.all(promises)
		.then(() => {
			currentUser.getIdToken(true)
			history.push("/")
			window.document.location.reload(true)
		})
		.catch(() => {
			setError("Failed to update account")
		})
		.finally(() => {
			setLoading(false)
		})
	}

  return (
	<div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
	<div style={{ width: 320 }}>
	  <Card>
			<CardContent>
			<h2>Update Profile</h2>
			{error && <Alert severity="error">{error}</Alert>}
			<FormGroup id="email">
				<InputLabel>Email</InputLabel>
				<Input
				type="email"
				onChange={(e) => setEmail(e.target.value)}
				required
				value={email}
				/>
			</FormGroup>
			<FormGroup id="displayName">
				<InputLabel>Username</InputLabel>
				<Input
				onChange={(e) => setDisplayName(e.target.value)}
				required
				value={displayName}
				/>
			</FormGroup>
			<ProfilePictureSettings setPicture={setProfilePicture}/>
			<FormGroup id="password">
				<InputLabel>Password</InputLabel>
				<Input
				type="password"
				onChange={(e) => setPassword(e.target.value)}
				placeholder="Leave blank to keep the same"
				/>
			</FormGroup>
			<FormGroup id="password-confirm">
				<InputLabel>Password Confirmation</InputLabel>
				<Input
				type="password"
				onChange={(e) => setPasswordConfirmation(e.target.value)}
				placeholder="Leave blank to keep the same"
				/>
			</FormGroup>
			<LoadingButton
				loading={loading}
				onClick={handleSubmit}
				variant="contained"
				style={{ marginTop: 16, marginBottom: 16 }}
			>
				Update
				</LoadingButton>
		  	<br />
		 	 <Link to="/">{"< Back"}</Link>
		</CardContent>
	  </Card>
	</div>
	</div>
  )
}
